<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/bg-1.jpeg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="1000"
          width="100%"
        >
          <base-heading title="Técnico Industrial Carlos Sarmiento Lora" />
          <base-heading title="¡Bienvenido!" />

          <!-- <base-body>
            Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality.
            incubator seed round massmarket.
            buyer agile development growth hacking business-to-consumer ecosystem
          </base-body> -->
          <br>
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <!-- http://ieindustrialtulua.edu.co/images_general/cronograma_actividades_apoyo.pdf CRONOGRAMA DE ACTIVIDADES DE APOYO -->
            <base-btn
              href="https://forms.gle/rQXDLdLAVUJg1haH8"
              target="_blank"
              title="PREMATRÍCULA"
              :style="$vuetify.breakpoint.smAndDown ? 'font-size: 3vw' : 'font-size: 1vw'"
            >
              PREMATRÍCULA
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">No olvides que</span>

            <base-btn
              href="https://www.facebook.com/Institución-Educativa-Técnico-Industrial-Carlos-Sarmiento-Lora-105441471591458/"
              class="pa-1"
              target="_blank"
              height="auto"
              text
            >
              Estamos también en Facebook
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
